import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import subscription from './modules/subscription'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appName: process.env.VUE_APP_NAME,
  },
  modules: {
    user,
    subscription,
  },
})
