import Cookies from "universal-cookie";
import axios from 'axios'

const states = {
  daysBeforeExpiration: '',
  expirationDate: '',
}

const mutations = {
  setDaysBeforeExpiration(state, payload) {
    state.daysBeforeExpiration = payload
  },
  setExpirationDate(state, payload) {
    state.expirationDate = payload
  }
}

const actions = {
  async getExpirationDate({commit}) {
    const cookie = new Cookies;
    try {
      const response = await axios.get(`${process.env.VUE_APP_URI}/api/get-expiration-date`);
      const result = response.data;

      // commit('setDaysBeforeExpiration', Number(result.count))
      // commit('setExpirationDate', result.renewal)

      if(result != '') {
        if(Number(result.count) > 0) {
          if(Number(result.count) < 30) {
            const message = `Your system access will expire in exactly ${Number(result.count)} days from now. Please renew on or before ${result.renewal}.` 
            alert(message)
          }
        } else {
          alert('Your system access is already expired. Please renew immediately.')
          cookie.remove('access_token', { 
            path    : '/',
            sameSite: true
          });
          cookie.remove('user', { 
            path    : '/',
            sameSite: true
          });
          // this.$router.replace({name: 'signIn'});
          window.location.href = "/"
        }
      }
    } catch(error) {
    }
  }
}

export default {
  states,
  actions,
  mutations,
}