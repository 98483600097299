<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/logo.svg')"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            PAYROLL MANAGEMENT SYSTEM
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>
    <perfect-scrollbar>
      <!--Navigation Items-->
      <v-list
        expand
        shaped
        dense
        class="vertical-nav-menu-items pr-5"
      >
        <nav-menu-link
          title="Dashboard"
          :to="{ name: 'dashboard' }"
        ></nav-menu-link>
        <nav-menu-link
          title="201"
          :to="{ name: 'employee-record' }"
        ></nav-menu-link>
        <!-- setups -->
        <nav-menu-section-title title="SETUPS"></nav-menu-section-title>
        <nav-menu-link
          title="Adjustment"
          :to="{ name: 'setup-adjustment' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Deduction"
          :to="{ name: 'setup-deduction' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Department"
          :to="{ name: 'setup-departments' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Payroll Period"
          :to="{ name: 'setup-payroll-periods' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Outlet"
          :to="{ name: 'setup-outlets' }"
        ></nav-menu-link>
        <nav-menu-link
          title="User Account"
          :to="{ name: 'setup-user-accounts' }"
        ></nav-menu-link>
        <nav-menu-link
          title="User Level"
          :to="{ name: 'setup-user-levels' }"
        ></nav-menu-link>
        <!-- transactions -->
        <nav-menu-section-title title="TRANSACTIONS"></nav-menu-section-title>
        <nav-menu-link
          title="Adjustment Entry"
          :to="{ name: 'transaction-adjustment-entries' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Attendance Entry"
          :to="{ name: 'transaction-attendance-entries' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Deductions"
          :to="{ name: 'transaction-deductions' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Overtime Request"
          :to="{ name: 'transaction-overtime-requests' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Payroll Change"
          :to="{ name: 'transaction-payroll-changes' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Scheduler"
          :to="{ name: 'transaction-scheduler' }"
        ></nav-menu-link>
        <!-- PROCESS -->
        <nav-menu-section-title title="PROCESS"></nav-menu-section-title>
        <nav-menu-link
          title="A-RE"
          :to="{ name: 'process-post-are' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Attendance Computation"
          :to="{ name: 'process-attendance-computation' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Payroll Computation"
          :to="{ name: 'process-payroll-computation' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Special Computation"
          :to="{ name: 'process-special-computation' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Payslip eBlast"
          :to="{ name: 'process-payslip-eblast' }"
        ></nav-menu-link>
        <nav-menu-link
          title="BIR 2316"
          :to="{ name: 'bir-2316' }"
        ></nav-menu-link>
        <!-- REPORTS -->
        <nav-menu-section-title title="REPORTS"></nav-menu-section-title>
        <nav-menu-link
          title="Adjustments"
          :to="{ name: 'report-adjustment' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Alphalist"
          :to="{ name: 'report-alphalist' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Attendance Summary"
          :to="{ name: 'report-attendance-summary' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Bank Report"
          :to="{ name: 'report-debit-advise' }"
        ></nav-menu-link>
        <!-- <nav-menu-link
          title="Coop Dues"
          :to="{ name: 'report-coop-dues' }"
        ></nav-menu-link> -->
        <nav-menu-link
          title="FS Entry"
          :to="{ name: 'report-fs-entry' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Government Loans"
          :to="{ name: 'report-government-loans' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Government Contributions"
          :to="{ name: 'report-government-contributions' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Labor Cost"
          :to="{ name: 'report-labor-cost' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Leave Summary"
          :to="{ name: 'report-leave-summary' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Other Deductions"
          :to="{ name: 'report-other-deductions' }"
        >
        </nav-menu-link>
        <nav-menu-link
          title="Payroll Register"
          :to="{ name: 'report-pay-register' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Payroll Register Special"
          :to="{ name: 'report-pay-register-special' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Payslip Acknowledgement"
          :to="{ name: 'report-payslip-acknowledgement' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Union Dues"
          :to="{ name: 'report-union-dues' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Withholding Tax"
          :to="{ name: 'report-wtax' }"
        ></nav-menu-link>
        <nav-menu-link
          title="13 month - Comparison"
          :to="{ name: 'report-13-month-comparison' }"
        ></nav-menu-link>
        <nav-menu-link
          title="13 month - Assume"
          :to="{ name: 'report-13-month-assume' }"
        ></nav-menu-link>
        <nav-menu-link
          title="13 month - Provision"
          :to="{ name: 'report-13-month-provision' }"
        ></nav-menu-link>
      </v-list>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mapActions } from 'vuex'
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    ...mapActions(['getUser']),
  },
  async created() {
    await this.$store.dispatch('getUser')
  },

  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

</style>
