import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'universal-cookie'
import interceptors from '@/functions/request'
import store from "../store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/201/:data?',
    name: 'employee-record',
    component: () => import('@/views/employee/index.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/adjustment',
    name: 'setup-adjustment',
    component: () => import('@/views/setups/adjustment/index.vue'),
  },
  {
    path: '/deduction',
    name: 'setup-deduction',
    component: () => import('@/views/setups/deduction/index.vue'),
  },
  {
    path: '/departments',
    name: 'setup-departments',
    component: () => import('@/views/setups/department/index.vue'),
  },
  {
    path: '/payroll-periods',
    name: 'setup-payroll-periods',
    component: () => import('@/views/setups/payroll-period/index.vue'),
  },
  {
    path: '/outlets',
    name: 'setup-outlets',
    component: () => import('@/views/setups/outlet-store/index.vue'),
  },
  {
    path: '/user-accounts',
    name: 'setup-user-accounts',
    component: () => import('@/views/setups/user-account/index.vue'),
  },
  {
    path: '/user-levels',
    name: 'setup-user-levels',
    component: () => import('@/views/setups/user-level/index.vue'),
  },
  {
    path: '/adjustment-entries',
    name: 'transaction-adjustment-entries',
    component: () => import('@/views/transactions/adjustment-entry/index.vue'),
  },
  {
    path: '/attendance-entries',
    name: 'transaction-attendance-entries',
    component: () => import('@/views/transactions/attendance-entry/index.vue'),
  },
  {
    path: '/deductions',
    name: 'transaction-deductions',
    component: () => import('@/views/transactions/deduction/index.vue'),
  },
  {
    path: '/overtime-request',
    name: 'transaction-overtime-requests',
    component: () => import('@/views/transactions/overtime-request/index.vue'),
  },
  {
    path: '/payroll-change',
    name: 'transaction-payroll-changes',
    component: () => import('@/views/transactions/payroll-change/index.vue'),
  },
  {
    path: '/scheduler',
    name: 'transaction-scheduler',
    component: () => import('@/views/transactions/scheduler/index.vue'),
  },
  {
    path: '/a-re',
    name: 'process-post-are',
    component: () => import('@/views/process/a-re.vue'),
  },
  {
    path: '/attendance-computation',
    name: 'process-attendance-computation',
    component: () => import('@/views/process/attendance-computation.vue'),
  },
  {
    path: '/payroll-computation',
    name: 'process-payroll-computation',
    component: () => import('@/views/process/payroll-computation.vue'),
  },
  {
    path: '/special-computation',
    name: 'process-special-computation',
    component: () => import('@/views/process/special-computation.vue'),
  },
  {
    path: '/payslip-eblast',
    name: 'process-payslip-eblast',
    component: () => import('@/views/process/payslip-eblast.vue'),
  },
  {
    path: '/bir-2316',
    name: 'bir-2316',
    component: () => import('@/views/process/bir-2316.vue'),
  },

  // reports
  {
    path: '/reports/adjustments',
    name: 'report-adjustment',
    component: () => import('@/views/reports/adjustment/index.vue'),
  },
  {
    path: '/reports/alphalist',
    name: 'report-alphalist',
    component: () => import('@/views/reports/alphalist/form.vue'),
  },
  {
    path: '/reports/attendance-summary',
    name: 'report-attendance-summary',
    component: () => import('@/views/reports/attendance-summary/form.vue'),
  },
  {
    path: '/reports/debit-advise',
    name: 'report-debit-advise',
    component: () => import('@/views/reports/debit-advise/index.vue'),
  },
  {
    path: '/reports/coop-dues',
    name: 'report-coop-dues',
    component: () => import('@/views/reports/coop-dues/index.vue'),
  },
  {
    path: '/reports/fs-entry',
    name: 'report-fs-entry',
    component: () => import('@/views/reports/fs-entry/form.vue'),
  },
  {
    path: '/reports/government-contribution',
    name: 'report-government-contributions',
    component: () => import('@/views/reports/government-contribution/index.vue'),
  },
  {
    path: '/reports/government-loans',
    name: 'report-government-loans',
    component: () => import('@/views/reports/government-loans/index.vue'),
  },
  {
    path: '/reports/labor-cost',
    name: 'report-labor-cost',
    component: () => import('@/views/reports/labor-cost/form.vue'),
  },
  {
    path: '/reports/leave-summary',
    name: 'report-leave-summary',
    component: () => import('@/views/reports/leave-summary/index.vue'),
  },
  {
    path: '/reports/other-deductions',
    name: 'report-other-deductions',
    component: () => import('@/views/reports/other-deductions/index.vue'),
  },
  {
    path: '/reports/pay-register',
    name: 'report-pay-register',
    component: () => import('@/views/reports/pay-register/index.vue'),
  },
  {
    path: '/reports/pay-register-special',
    name: 'report-pay-register-special',
    component: () => import('@/views/reports/pay-register-special/index.vue'),
  },
  {
    path: '/reports/payslip-acknowledgement',
    name: 'report-payslip-acknowledgement',
    component: () => import('@/views/reports/payslip-acknowledgement/form.vue'),
  },
  {
    path: '/reports/union-dues',
    name: 'report-union-dues',
    component: () => import('@/views/reports/union-dues/index.vue'),
  },
  {
    path: '/reports/wtax',
    name: 'report-wtax',
    component: () => import('@/views/reports/wtax/index.vue'),
  },
  {
    path: '/reports/13-month-comparison',
    name: 'report-13-month-comparison',
    component: () => import('@/views/reports/comparison-13-month/index.vue'),
  },
  {
    path: '/reports/13-month-assume',
    name: 'report-13-month-assume',
    component: () => import('@/views/reports/assume-13-month/index.vue'),
  },
  {
    path: '/reports/13-month-provision',
    name: 'report-13-month-provision',
    component: () => import('@/views/reports/provision-13-month/index.vue'),
  },
  {
    path: '/account-settings',
    name: 'account-settings',
    component: () => import('@/views/account-settings/AccountSettings.vue'),
  },
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/auth/otp',
    name: 'otp',
    component: () => import('@/views/auth/Otp.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const cookies = new Cookies()
  const accessToken = cookies.get('access_token')

		// await store.dispatch('getUser')
  if (to.path === '/auth/login' || to.path === '/auth/otp' || to.path === '/auth/forgot-password') {
    if (accessToken !== undefined) {
      interceptors()
      next({ path: '/dashboard', replace: true })
    } else {
      next()
    }
  } else if (accessToken === undefined) {
    next({ path: '/auth/login', replace: true })
  } else {
    interceptors()
		await store.dispatch('getExpirationDate')
    next()
  }
})

export default router
